<template>
  <div class="sam-table-row row" 
  @click="clickableRow ? rowAction() : {}"
  :class="{'hoverable': hoverable, 
  'noBorder': noBorder, 
  'align-items-center': headerRow,
  'pt-0': headerRowNoSpace,
  'desktopView': desktopView
  }"
  >
    <slot/>
  </div>
</template>

<script>


export default {
  name: 'SamTableRow',
  props: {
    id: {
      type:String,
      default: ''
    },
    hoverable: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    headerRow: {
      type: Boolean,
      default: false
    },
    noSpace: {
      type: Boolean,
      default: false
    },
    desktopView: {
      type: Boolean,
      default: false
    },
    clickableRow: {
      type: Boolean,
      default: false
    },
    headerRowNoSpace: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    rowAction(){
      this.$emit('rowAction');
  }
}
}
</script>

<style lang="scss" scoped>

.sam-table-row {
  min-height: 1.5rem;
  padding: 1.5rem 0;
  transition: $mainTransition;
  position: relative;
  border-radius: $mainRadius;
}



.sam-table-row::after {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid $lightGrey;
  bottom: 0;
  left: 15px;
  right: 15px;
}

.noBorder::after {
  content: "";
  display: block;
  position: absolute;
  border-bottom: 1px solid transparent;
  bottom: 0;
  left: 15px;
  right: 15px;
}
.hoverable {
  cursor: pointer;
}

@media (min-width: 768px) {

  .hoverable {
    position: relative;
    z-index: 3;
  }
  .sam-table-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .hoverable:hover::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 15px;
    left: 15px;
    bottom: 0;
    background-color: $hoverColor;
    border-radius: $mainRadius;
    z-index: -1;
    transition: 0.3s ease;
  }
}
</style>
