var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sam-table-column col-sm-12",class:{
    'col-md-3': _vm.column4 && !_vm.column6 && !_vm.spaceColumn,
    'col-md-2': _vm.column6,
    'col-md-1': _vm.spaceColumn,
    'col-6': _vm.mobileColumn,
    'mb-3': _vm.mobileColumn,
    'd-flex align-items-start': _vm.imageColumn,
    'mobile-visible': _vm.disabledOnDekstop,
    'mt-4': _vm.spaceOnTop,
    'p-0': _vm.noPadding,
    'tableView': !_vm.mobileView,
    'spaceRight': _vm.spaceRight,

    }},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }