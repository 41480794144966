<template>
    <div 
        class="sam-table-column col-sm-12"
         :class="{
        'col-md-3': column4 && !column6 && !spaceColumn,
        'col-md-2': column6,
        'col-md-1': spaceColumn,
        'col-6': mobileColumn,
        'mb-3': mobileColumn,
        'd-flex align-items-start': imageColumn,
        'mobile-visible': disabledOnDekstop,
        'mt-4': spaceOnTop,
        'p-0': noPadding,
        'tableView': !mobileView,
        'spaceRight': spaceRight,

        }">
        <slot/>
    </div>
</template>

<script>


export default {
    name: 'SamTableColumn',
    props: {
        id: {
            type: String,
            default: ''
        },
        column4: {
            type: Boolean,
            default: true
        },
        imageColumn: {
            type: Boolean,
            default: false
        },
        disabledOnDekstop: {
            type: Boolean,
            default: false
        },
        mobileColumn: {
            type: Boolean,
            default: false
        },
        spaceOnTop: {
            type: Boolean,
            default: false
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        mobileView: {
            type: Boolean,
            default: false
        },
        spaceRight: {
            type: Boolean,
            default: false
        },
        column6: {
            type: Boolean,
            default: false
        },
        spaceColumn: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .mobile-visible {
    display: none;
  }
}

.spaceRight {
  padding-right: 35px !important;
}
</style>
