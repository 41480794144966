<template>
  <div class="sam-accordion accordion mb-4 mt-4">
    <b-button v-b-toggle="accordionId" class="sam-accordion-button accordion-button collapsed title">
      <h2 class="sam-accordion-header mb-4">
        {{ title }}<span v-if="titlePayload" class="text-color-secondary">&nbsp;{{ titlePayload }}</span>&nbsp;
        <img v-if="tooltip" id="p-header-tooltip" src="@/assets/images/tooltip.svg" title="Tooltip" />
      </h2>
      <b-tooltip v-if="tooltip" v-b-tooltip="'Delete without warning'" target="p-header-tooltip" triggers="hover">{{tooltipText}}</b-tooltip>
    </b-button>
    <b-collapse :visible="open" :id="accordionId">
      <div class="sam-accordion-body mb-4">
        <slot/>
      </div>
    </b-collapse>
  </div>
</template>

<script>


export default {
  name: 'SamAccordion',
  components: {},
  compatConfig: { MODE: 3 },
  data() {
    return {
      open: this.defaultOpen ? true : false
    }
  },
  props: {
    accordionId: {
      type:String,
      default: ''
    },
    titleOpen: {
      type:String,
      default: ''
    },
    titleClose: {
      type:String,
      default: ''
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    title: {
      type:String,
      default: ''
    },
    titlePayload: {
      type: String,
      default: ''
    },
    tooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type:String,
      default: '' 
    }
  }
}
</script>

<style lang="scss" scoped>

.sam-accordion {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  position: relative;
}

.sam-accordion-header {
    text-align: left;
}

@media (max-width: 768px) {
    .sam-accordion-header {
        padding-right: 23px;
    } 
}

.sam-accordion-button.accordion-button.collapsed::after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0px;
  top:7px;
  width: 23px;
  height: 23px;
  background: url("../assets/images/cross.svg");
   background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(-90deg);
  transition: 0.3s;
}

.sam-accordion-button.accordion-button:not(.collapsed)::after {
  content: '';
  display: inline-block;
  position: absolute;
  right: 0px;
  width: 23px;
  height: 23px;
  top: 7px;
  background: url("../assets/images/cross.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: rotate(45deg);
  transition: 0.3s;
}

.sam-accordion-button,
.sam-accordion-button.accordion-button {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background-color: #fff;
  color: black;
}
 
.sam-accordion-button:focus:not(:focus-visible) {
    background-color: #fff;
    color: black
}

.p-header-tooltip {
    vertical-align: auto;
}
</style>
